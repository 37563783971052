
import { defineComponent, inject, onMounted, ref } from "vue";
import { init, reInit } from "./index";

export default defineComponent({
  directives: {
    slider: {
      mounted(el, { value }) {
        init(el, value.props);
      },
      updated(el) {
        reInit(el);
      },
    },
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    refKey: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const sliderRef = ref<HTMLElement>();
    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject<(el: HTMLElement | undefined) => void>(
          `bind[${props.refKey}]`
        );
        if (bind) {
          bind(sliderRef.value);
        }
      }
    };

    onMounted(() => {
      bindInstance();
    });

    return {
      props,
      ...context,
      sliderRef,
    };
  },
});
