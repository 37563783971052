<template>
  <div class="dropdown" data-placement="bottom-end" :id="id">

    <a
      href="#"
      class="dropdown-toggle button inline-block bg-theme-1 text-white w-60"
    >
      Sources: {{ selectedName }}
      <ChevronDownIcon class="w-4 h-4 ml-2 inline-block"></ChevronDownIcon>
    </a>
    <div class="dropdown-box w-600" :id="`${id}-dropdown-content`" >
      <div class="dropdown-box__content box p-2  ">
        <div class="grid grid-cols-9 gap-1 p-2 min-h-200">
          <div class="col-span-2">
            <p class="text-lg font-bold mb-3">Languages</p>
            <div class="flex items-center text-gray-700 mt-2 "
                 v-for="(option) in languages" :key="`language-source-${option}`"
            >
              <input
                :id="`vertical-language-source-${option}`"
                :name="`vertical-language-source-${option}`"
                type="radio"
                class="input border mr-2"
                v-model="selectedLanguage"
                :value="option"/>
              <label class="cursor-pointer select-none uppercase"
                     :for="`vertical-language-source-${option}`">
                {{ option  }}
              </label>
            </div>
          </div>
          <div class="border-l border-gray-300 pl-5 col-span-3">
            <p class="text-lg font-bold mb-3">Countries</p>
            <div class="overflow-y-auto h-56 block">

              <div class="flex items-center text-gray-700">
                <input
                  :id="`country-source-all`"
                  type="checkbox"
                  class="input border mr-2"
                  v-model="selectedAllCountries"
                  value="all"/>
                <label class="cursor-pointer select-none"
                       :for="`country-source-all`">
                  All
                </label>
              </div>
              <div class="flex items-center text-gray-700 mt-2 "
                   v-for="(option) in countries" :key="`country-source-${option.id}`"
              >
                <input
                  :id="`country-source-${option.id}`"
                  type="checkbox"
                  class="input border mr-2"
                  v-model="selectedCountries"
                  :value="option.id"
                  :disabled="selectedAllCountries.length > 0"/>
                <label class="cursor-pointer select-none"
                       :class="selectedAllCountries.length > 0 ? 'text-gray-400' : ''"
                       :for="`country-source-${option.id}`">
                  {{ option.name }}
                </label>
              </div>
            </div>

          </div>
          <div class="border-l border-gray-300 pl-5 col-span-4">
            <p class="text-lg font-bold mb-3">Sources</p>
            <div class="flex items-center text-gray-700 mt-2 mb-2">
              <input
                :id="`source-source-all`"
                type="checkbox"
                class="input border mr-2"
                v-model="selectedAllSources"
                value="all"/>
              <label class="cursor-pointer select-none"
                     :for="`source-source-all`">
                All
              </label>
            </div>
            <input type="text"
                   v-model="sourcesFilter"
                   class="input input--sm w-full border"
                  :disabled="selectedAllSources.length > 0"/>
            <div class="overflow-y-auto h-56 block">
              <div
                  class="flex items-center text-gray-700 mt-2 "
                  v-for="(option) in sourcesFiltered"
                  :key="`source-source-${option.id}`"
              >
                <input
                  :id="`source-source-${option.id}`"
                  type="checkbox"
                  class="input border mr-2"
                  v-model="selectedSources"
                  :value="option.id"
                  :disabled="selectedAllSources.length > 0"/>
                  <label class="cursor-pointer select-none"
                         :class="selectedAllSources.length > 0 ? 'text-gray-400' : ''"
                         :for="`source-source-${option.id}`">
                    {{ option.name }} ( {{option.type}})
                  </label>
            </div>
            </div>
          </div>
        </div>
        <div class="flex mt-4">
          <a href="#"
             :class="loading ?
                      'bg-gray-300 hover:bg-gray-300 text-gray-700' :
                      isValidSelection ?
                        'bg-theme-1 text-white hover:bg-theme-3' :
                        'bg-gray-300 hover:bg-gray-300 text-gray-700'"
             class="flex-grow button w-24 inline-block mr-1 mb-2 text-white "
             :disabled="loading || !isValidSelection"
             @click.prevent="setSelected"
            >
              <span v-show="!loading && isValidSelection">Apply</span>
              <span v-show="!loading && !isValidSelection">Select Values</span>
              <span v-show="loading">Loading</span>
          </a>
        </div>
      </div>
    </div>

  </div>

</template>
<script lang="ts">

import {computed, defineComponent, inject, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {slugify} from 'transliteration';

export default defineComponent(
  {

    emits: ['selected'],

  setup(props, ctx) {
    const store = useStore();
    const loading = ref(false);
    const lodash = inject<any>('lodash');
    const id = ref('sources-dropdown');
    const languages = ref([]);
    const countries = ref([]);
    const sources = ref([]);
    const sourcesFiltered = ref([]);
    const sourcesFilter = ref([]);
    const selectedLanguage = ref(null);
    const selectedCountries = ref([]);
    const selectedAllCountries = ref([]);
    const selectedAllSources = ref([]);
    const selectedSources = ref([]);

    const selectedName = computed(() => {
      const selection = selectedCountries.value.length > 0 ? 'Some' : 'All';
      return selectedLanguage.value
          ? `${selection} in ${selectedLanguage.value.toUpperCase()}`
          : 'ALL';
    });

      const isValidSelection = computed(() => {
        return selectedLanguage.value && (selectedAllCountries.value.length > 0 || selectedCountries.value.length > 0) && (selectedAllSources.value.length > 0 || selectedSources.value.length > 0);
      });

    // Clear other country selections
    watch(selectedAllCountries, (newValue) => {
      if (newValue.includes('all')) {
        selectedCountries.value = [];
      }
    });

    // Remove 'all' if any country is selected
    watch(selectedCountries, (newValue) => {
      if (newValue.length > 0 && selectedAllCountries.value.includes('all')) {
        selectedAllCountries.value = [];
      }
    });

    watch(selectedCountries, (newVal) => {
      if (newVal.length > 0) {
        fetchSources();
      }
    }, { immediate: false });


    watch(selectedLanguage, (newValue) => {
      if (!newValue) return;
      loading.value = true;
      fetchCountriesForLanguage(newValue);
    });

    const fetchCountriesForLanguage = (language) => {
      store.dispatch('sources/GET_COUNTRIES', language).then(response => {
        countries.value = lodash.map(response.data, country => ({ id: slugify(country), name: country }));
        updateCountrySelectionFromUrl();
        selectedAllCountries.value = ['all'];
      }).finally(() => {
        loading.value = false;
      });
    };

    const updateCountrySelectionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlCountries = urlParams.get('countries');
      if (urlCountries) {
        const decodedCountries = decodeURIComponent(urlCountries).split('|');
        const filteredCountries = countries.value.filter(c => decodedCountries.includes(c.name));
        if (filteredCountries.length > 0) {
          selectedCountries.value = filteredCountries.map(c => c.id);
          selectedAllCountries.value = [];
        } else {
          selectedAllCountries.value = ['all'];
        }
      } else {
        selectedAllCountries.value = ['all'];
      }
    };


    onMounted(() => {
      loading.value = true;
      store.dispatch('sources/GET_LANGUAGES').then(response => {
        languages.value = response.data;
        const urlParams = new URLSearchParams(window.location.search);
        const sourceLang = urlParams.get('sourceLang') || response.data[0];
        selectedLanguage.value = sourceLang;
        return store.dispatch('sources/GET_COUNTRIES', sourceLang);
      }).then(() => {
        updateCountrySelectionFromUrl();
        return fetchSources();
      }).then(() => {
        loading.value = false;
      });
    });

    function fetchSources() {
      const countryNames = selectedCountries.value.map(id => countries.value.find(c => c.id === id)?.name);
      store.dispatch('sources/GET_SOURCES', {
        lang: selectedLanguage.value,
        countries: countryNames
      }).then(response => {
        sources.value = response.data;
        selectedAllSources.value = ['all'];
        sourcesFiltered.value = response.data;
      });
    }

      const setSelected = () => {
        cash('#' + id.value).dropdown('hide');
        const selectedCountryNames = selectedCountries.value.map(id => {
          const country = countries.value.find(c => c.id === id);
          return country ? country.name : null;
        }).filter(name => name != null);

        const selected = {
          language: selectedLanguage.value,
          countries: selectedAllCountries.value.length > 0 ? [] : selectedCountryNames,
          sources: selectedAllSources.value.length > 0 ? [] : selectedSources.value,
        };

        updateUrl(selected);
        ctx.emit('selected', selected);
      };

    function updateUrl(selected) {
      const params = new URLSearchParams(window.location.search);
      const countryIds = selected.countries.length > 0 ? selected.countries : ['all'];
      params.set('countries', countryIds.join('|'));
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    }



      return {
        id,
        loading,
        languages,
        countries,
        sources,
        sourcesFilter,
        sourcesFiltered,
        selectedLanguage,
        selectedCountries,
        selectedAllCountries,
        selectedSources,
        selectedAllSources,
        setSelected,
        selectedName,
        isValidSelection

      };
    }
  });
</script>



