

import {computed, defineComponent, inject, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {slugify} from 'transliteration';

export default defineComponent(
  {

    emits: ['selected'],

  setup(props, ctx) {
    const store = useStore();
    const loading = ref(false);
    const lodash = inject<any>('lodash');
    const id = ref('sources-dropdown');
    const languages = ref([]);
    const countries = ref([]);
    const sources = ref([]);
    const sourcesFiltered = ref([]);
    const sourcesFilter = ref([]);
    const selectedLanguage = ref(null);
    const selectedCountries = ref([]);
    const selectedAllCountries = ref([]);
    const selectedAllSources = ref([]);
    const selectedSources = ref([]);

    const selectedName = computed(() => {
      const selection = selectedCountries.value.length > 0 ? 'Some' : 'All';
      return selectedLanguage.value
          ? `${selection} in ${selectedLanguage.value.toUpperCase()}`
          : 'ALL';
    });

      const isValidSelection = computed(() => {
        return selectedLanguage.value && (selectedAllCountries.value.length > 0 || selectedCountries.value.length > 0) && (selectedAllSources.value.length > 0 || selectedSources.value.length > 0);
      });

    // Clear other country selections
    watch(selectedAllCountries, (newValue) => {
      if (newValue.includes('all')) {
        selectedCountries.value = [];
      }
    });

    // Remove 'all' if any country is selected
    watch(selectedCountries, (newValue) => {
      if (newValue.length > 0 && selectedAllCountries.value.includes('all')) {
        selectedAllCountries.value = [];
      }
    });

    watch(selectedCountries, (newVal) => {
      if (newVal.length > 0) {
        fetchSources();
      }
    }, { immediate: false });


    watch(selectedLanguage, (newValue) => {
      if (!newValue) return;
      loading.value = true;
      fetchCountriesForLanguage(newValue);
    });

    const fetchCountriesForLanguage = (language) => {
      store.dispatch('sources/GET_COUNTRIES', language).then(response => {
        countries.value = lodash.map(response.data, country => ({ id: slugify(country), name: country }));
        updateCountrySelectionFromUrl();
        selectedAllCountries.value = ['all'];
      }).finally(() => {
        loading.value = false;
      });
    };

    const updateCountrySelectionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlCountries = urlParams.get('countries');
      if (urlCountries) {
        const decodedCountries = decodeURIComponent(urlCountries).split('|');
        const filteredCountries = countries.value.filter(c => decodedCountries.includes(c.name));
        if (filteredCountries.length > 0) {
          selectedCountries.value = filteredCountries.map(c => c.id);
          selectedAllCountries.value = [];
        } else {
          selectedAllCountries.value = ['all'];
        }
      } else {
        selectedAllCountries.value = ['all'];
      }
    };


    onMounted(() => {
      loading.value = true;
      store.dispatch('sources/GET_LANGUAGES').then(response => {
        languages.value = response.data;
        const urlParams = new URLSearchParams(window.location.search);
        const sourceLang = urlParams.get('sourceLang') || response.data[0];
        selectedLanguage.value = sourceLang;
        return store.dispatch('sources/GET_COUNTRIES', sourceLang);
      }).then(() => {
        updateCountrySelectionFromUrl();
        return fetchSources();
      }).then(() => {
        loading.value = false;
      });
    });

    function fetchSources() {
      const countryNames = selectedCountries.value.map(id => countries.value.find(c => c.id === id)?.name);
      store.dispatch('sources/GET_SOURCES', {
        lang: selectedLanguage.value,
        countries: countryNames
      }).then(response => {
        sources.value = response.data;
        selectedAllSources.value = ['all'];
        sourcesFiltered.value = response.data;
      });
    }

      const setSelected = () => {
        cash('#' + id.value).dropdown('hide');
        const selectedCountryNames = selectedCountries.value.map(id => {
          const country = countries.value.find(c => c.id === id);
          return country ? country.name : null;
        }).filter(name => name != null);

        const selected = {
          language: selectedLanguage.value,
          countries: selectedAllCountries.value.length > 0 ? [] : selectedCountryNames,
          sources: selectedAllSources.value.length > 0 ? [] : selectedSources.value,
        };

        updateUrl(selected);
        ctx.emit('selected', selected);
      };

    function updateUrl(selected) {
      const params = new URLSearchParams(window.location.search);
      const countryIds = selected.countries.length > 0 ? selected.countries : ['all'];
      params.set('countries', countryIds.join('|'));
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    }



      return {
        id,
        loading,
        languages,
        countries,
        sources,
        sourcesFilter,
        sourcesFiltered,
        selectedLanguage,
        selectedCountries,
        selectedAllCountries,
        selectedSources,
        selectedAllSources,
        setSelected,
        selectedName,
        isValidSelection

      };
    }
  });
