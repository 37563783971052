

import {computed, defineComponent, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default defineComponent({

  setup(){

    const store= useStore();
    const router = useRouter();
    const searchHistory = computed(() => store.getters['searchHistory/all'] )
    const searchTotal = computed(() => store.getters['searchHistory/total'] )

    const navigateUrl = (index)=> {
      const historyQuery =  searchHistory.value[index];
      return store.getters['main/searchPath'](router.currentRoute.value.path,historyQuery)
    }

    const deleteHistory = (id)=> {
      console.log("Deleting history with id:", id);
      store.dispatch('searchHistory/DELETE', id)

    }

    onMounted(()=>{
      console.log("window ",window.parent)
      window.top.postMessage('hello', '*')
      store.dispatch('searchHistory/READ', null)
    })

    return {
      searchHistory,
      searchTotal,
      navigateUrl,
      deleteHistory,
    }

  },

})

