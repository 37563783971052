import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import {State as LocalState } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET](state: S, history:any): void;
  [MutationTypes.ADD](state: S, history:any): void;
  [MutationTypes.DELETE](state: S, history:any): void;


};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {

  [MutationTypes.SET](state: LocalState, history){
    state.data=history
  },
  [MutationTypes.ADD](state, newItem) {
    if (newItem) {
      state.data.push(newItem);
    }
  },
  [MutationTypes.DELETE](state, id) {
    state.data = state.data.filter(item => item.id !== id);
  }


};
